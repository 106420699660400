import Briefcase from "./Briefcase";
import ContactGroupList from "./ContactGroupList";
import ContactList from "./ContactList";
import Cooperative from "./Cooperative";
import NoMatch from "./NoMatch";
import PackageDownload from "./PackageDownload";
import MultiFactorKeyList from "./MultiFactorKeyList";
import PackageAddFiles from "./PackageAddFiles";
import PackageForward from "./PackageForward";
import PackageRequest from "./PackageRequest";
import PackagesApproveList from "./PackagesApproveList";
import PackagesInbox from "./PackagesInbox";
import PackagesOutbox from "./PackagesOutbox";
import PackageUpload from "./PackageUpload";

export const routesMap: any = {
    Dashboard: {
        path: "/",
        component: PackagesInbox,
        exact: true,
        menuKey: "packages-inbox",
        menuOpenKey: "packages"
    },
    PackagesInbox: {
        path: "/packages/inbox",
        exact: true,
        component: PackagesInbox,
        menuKey: "packages-inbox",
        menuOpenKey: "packages"
    },
    PackagesOutbox: {
        path: "/packages/outbox",
        exact: true,
        component: PackagesOutbox,
        menuKey: "packages-outbox",
        menuOpenKey: "packages"
    },
    PackagesApproveList: {
        path: "/packages/approve-list",
        exact: true,
        component: PackagesApproveList,
        menuKey: "packages-approve-list",
        menuOpenKey: "packages"
    },
    UploadPackage: {
        path: "/upload-package",
        exact: true,
        component: PackageUpload,
        menuKey: "package-upload",
        firstState: "UPLOAD_FORM",
    },
    AddToBriefcase: {
        path: "/add-to-briefcase",
        exact: true,
        component: PackageUpload,
        menuKey: "briefcase",
        firstState: "ADD_TO_BRIEFCASE_FORM"
    },
    PackageRequest: {
        path: "/package-request",
        exact: true,
        component: PackageRequest,
        menuKey: "package-request"
    },
    PackageForward: {
        path: "/package-forward/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})",
        exact: true,
        component: PackageForward,
        menuKey: "package-forward"
    },
    PackageAddFiles: {
        path: "/package/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/add-files",
        exact: true,
        component: PackageAddFiles,
        menuKey: "packages-outbox"
    },
    PackageRequested: {
        path: "/package-requested/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})",
        exact: true,
        component: PackageUpload,
        menuKey: "package-requested",
        firstState: "REQUEST_UPLOAD_FORM"
    },
    DownloadPackage: {
        path: "/packages/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})",
        component: PackageDownload,
    },
    MultiFactorKeyList: {
        path: "/multi-factor-key/list",
        component: MultiFactorKeyList
    },
    ContactList: {
        path: "/contact-list",
        exact: true,
        component: ContactList,
        menuKey: "contact-list",
        menuOpenKey: "contacts"
    },
    ContactGroupList: {
        path: "/contact-group-list",
        exact: true,
        component: ContactGroupList,
        menuKey: "contact-group-list",
        menuOpenKey: "contacts"
    },
    Briefcase: {
        path: "/briefcase",
        exact: true,
        component: Briefcase,
        menuKey: "briefcase"
    },
    Cooperative: {
        path: "/cooperative",
        exact: true,
        component: Cooperative,
        menuKey: "cooperative"
    },
    NoMatch: {
        path: "*",
        exact: false,
        component: NoMatch
    },
};

// @ts-ignore
export const routes = Object.keys(routesMap).map(key => routesMap[key]);
