import {
    AuditOutlined,
    BarsOutlined, CheckCircleFilled,
    CheckCircleOutlined, CloseCircleFilled,
    CloseCircleOutlined,
    ColumnHeightOutlined,
    DeleteFilled,
    EditFilled,
    ExclamationCircleFilled,
    FileUnknownFilled,
    FolderAddFilled,
    ForwardFilled,
    ForwardOutlined,
    GlobalOutlined,
    LockFilled,
    MailFilled,
    PushpinFilled, PushpinOutlined, QuestionCircleFilled,
    ShareAltOutlined,
    StarFilled,
    StarOutlined,
} from '@ant-design/icons';
import {Button, Dropdown, Menu, message, Space, Table} from 'antd';
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import Column from 'antd/lib/table/Column';
import {useContext, useState} from "react";
import {ImSortNumbericDesc} from "react-icons/im";
import {useHistory} from "react-router";
import {Link} from 'react-router-dom';
import {AppContextContext, PackageServiceContext} from "../Contexts";
import {Package, PackageAccessType, PackageCheckState, PackageDataIntegrity, PackageFlag, PackageWorkflowState} from "../domain/Package";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import FormatUtils from "../utils/FormatUtils";
import ModalOperation from "./common/ModalOperation";
import OkLeftPopconfirm from "./OkLeftPopconfirm";
import PackageModal from "./PackageModal";
import {routesMap} from "./Routes";
import SetApproverModal from "./SetApproverModal";

function PackageList(props: any) {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const intlMessage = useIntlMessage("packages-inbox-outbox");
    const history = useHistory();
    const [modal, setModal] = useState<any>({visible: false, title: "", operation: ModalOperation.Update, model: null});

    const [setApproverModal, setSetApproverModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});

    const forceUpdate = useForceUpdate();

    const onRow = (record: Package, index: any) => {
        return {
            onDoubleClick: () => {
                history.push(routesMap.DownloadPackage.path.replace(/:id.*$/, record.id!));
            },
        };
    };

    const ownPackage = (aPackage: Package): boolean => {
        return !!appContext.user && !!aPackage.sender && (appContext.user.email === aPackage.sender.email)
    }

    const iAmRecipient = (aPackage: Package): boolean => {
        // jsem interni prijemce?
        aPackage.internalRecipients && aPackage.internalRecipients?.forEach(recipient => {
            if (recipient.email === appContext.user?.email) {
                return true;
            }
        });
        return false;
    }

    const renderRowClass = (aPackage: Package): string => {

        const css = [];

        const ownPackageFlag = ownPackage(aPackage);

        if (aPackage.userFlags && !aPackage.userFlags!.viewed && ['INBOX', 'APPROVE_LIST'].includes(props.tableType)) {
            css.push("unreaded");
        }

        const colorCss = [];

        if (props.tableType === 'APPROVE_LIST') {
            if (aPackage.approved) {
                colorCss.push("green");
            } else if (aPackage.disapproved) {
                colorCss.push("danger");
            } else {
                colorCss.push("warning");
            }

        } else {

            if (aPackage.workflowState === PackageWorkflowState.REQUESTED) {
                colorCss.push("green");
            }

            switch (aPackage.checkState) {
                case PackageCheckState.UNKNOWN:
                    colorCss.push("warning");
                    break;
                case PackageCheckState.QUARANTINED:
                    colorCss.push("danger");
                    break;
            }
        }

        if (colorCss.length == 0) colorCss.push("info");

        return css.concat(colorCss).join(" ");
    };

    return (
        <div>
            <Table dataSource={props.data} size="middle" onChange={props.tableHandler.onTableChange}
                   showSorterTooltip={false}
                   pagination={props.tableHandler.pagination} rowKey="id" onRow={onRow} rowClassName={renderRowClass}
                   className={"package-list"}>

                <Column key={"userFlags.flagged"} render={(text, row: Package) => renderUserFlags(row)} width={30}/>

                <Column dataIndex="uploaded"
                        key="uploaded" width={120}
                        title={intlMessage("packages-inbox-outbox.uploaded")}
                        sorter={true}
                        sortOrder={props.tableHandler.columnSortOrder("uploaded")}
                        sortDirections={["ascend", "descend", "ascend"]}
                        render={(text, row, index) => FormatUtils.formatDate(text)} className={"min-width-150"}/>
                <Column dataIndex="name" title={intlMessage("packages-inbox-outbox.name")}
                        render={renderName} className={"min-width-150"}/>
                <Column dataIndex="checkState" width={120}
                        title={intlMessage("packages-inbox-outbox.state")}
                        render={renderState} className={"min-width-150"}/>

                {props.tableType === "APPROVE_LIST" &&
                    <Column dataIndex="sender"
                            title={intlMessage("packages-inbox-outbox.sender")}
                            render={renderSender}/>
                }

                {["INBOX", "COOPERATIVE"].includes(props.tableType) &&
                    <Column dataIndex="sender"
                            title={intlMessage("packages-inbox-outbox.sender")}
                            render={renderSender}/>
                }


                {["OUTBOX", "COOPERATIVE", "APPROVE_LIST"].includes(props.tableType) && <Column dataIndex="recipients"
                                                                                                title={intlMessage("packages-inbox-outbox.recipients")}
                                                                                                render={renderRecipients}/>
                }


                <Column dataIndex={"activeSize"} key="activeSize" render={renderSize} width={120}
                        title={intlMessage("packages-inbox-outbox.size")} sorter={true}
                        sortDirections={["ascend", "descend", "ascend"]}
                        sortOrder={props.tableHandler.columnSortOrder("activeSize")}/>
                {props.tableType !== "APPROVE_LIST" &&
                    <Column title={intlMessage("common.action")} render={renderActions}
                            width={"70px"}/>
                }
            </Table>

            {props.tableType === 'APPROVE_LIST' ?
                <div className={"clue"}>
                    <div className={"clue-item green"}/>
                    {intlMessage("package.approve-state.approved")}
                    <div className={"clue-item warning"}/>
                    {intlMessage("package.approve-state.not-modified")}
                    <div className={"clue-item danger"}/>
                    {intlMessage("package.approve-state.disapproved")}
                </div> :
                <div className={"clue"}>
                    <div className={"clue-item info"}/>
                    {intlMessage("PackageCheckState.CLEAN")}
                    <div className={"clue-item warning"}/>
                    {intlMessage("PackageCheckState.UNKNOWN")}
                    <div className={"clue-item danger"}/>
                    {intlMessage("PackageCheckState.QUARANTINED")}
                    {props.tableType !== "BRIEFCASE" &&
                        <>
                            <div className={"clue-item green"}/>
                            {intlMessage("PackageWorkflowState.REQUESTED")}
                        </>
                    }
                </div>}

            {modal.visible && modal.model ?
                <PackageModal visible={modal.visible}
                              title={modal.title}
                              editMode={modal.operation === ModalOperation.Update}
                              model={modal.model}
                              onOk={onModalSave}
                              onCancel={() => setModal({visible: false})}/> : ""
            }

            {setApproverModal.visible && setApproverModal.model ?
                <SetApproverModal visible={setApproverModal.visible}
                                  title={setApproverModal.title}
                                  package={setApproverModal.model}
                                  type={"PACKAGE"}
                                  onOk={onSetApproverModalSave}
                                  onCancel={onSetApproverModalCancel}/> : ""}

        </div>
    )

    function onSetApproverModalCancel() {
        resetSetApproverModalState();
    }

    function onSetApproverModalSave() {
        resetSetApproverModalState();

        props.reload();
    }

    function resetSetApproverModalState() {
        setSetApproverModal(prevState => {
            return ({
                ...prevState,
                visible: false,
                title: "",
                operation: ModalOperation.Update,
                model: {}
            });
        });
    }

    function onModalSave() {
        setModal({visible: false});

        props.reload();
    }

    function renderSender(text: any, aPackage: Package) {
        if (aPackage.anonymousSenderName || aPackage.anonymousSenderEmail) {
            return <span>
                {aPackage.anonymousSenderName ? <>{aPackage.anonymousSenderName}<br/></> : ""}
                {aPackage.anonymousSenderEmail}
            </span>;
        } else if (aPackage.fromRegisteredUser) {
            return (<span>{aPackage.sender && !aPackage.sender.deleted &&
                ((aPackage.sender.firstName || aPackage.sender.lastName) ?
                    <>{aPackage.sender.firstName} {aPackage.sender.lastName}<br/> {aPackage.sender.email}</> : <>{aPackage.sender.email}</>)}</span>);
        } else {
            return (<span>{intlMessage('packages-inbox-outbox.unknown-sender')}</span>);
        }
    }

    function renderName(text: any, aPackage: Package) {
        let dataIntegrityWarning: any;

        if (aPackage.dataIntegrity === PackageDataIntegrity.INVALID) {
            dataIntegrityWarning = <ExclamationCircleFilled className={"color-red"} title={intlMessage("package-list.package-data-integrity.INVALID")}/>
        }
        if (aPackage.dataIntegrity === PackageDataIntegrity.INACCESSIBLE) {
            dataIntegrityWarning = <ExclamationCircleFilled className={"color-orange"} title={intlMessage("package-list.package-data-integrity.INACCESSIBLE")}/>
        }

        return (
            <span>
                <Space>
                    {aPackage.name ?
                        <Link to={routesMap.DownloadPackage.path.replace(/:id.*$/, aPackage.id!)}>{aPackage.name}</Link> :
                        <Link to={routesMap.DownloadPackage.path.replace(/:id.*$/, aPackage.id!)}><span
                            className={"package-no-name"}>{intlMessage('packages-list.no-name')}</span></Link>}

                </Space>
                <br/>
                <div className={"package-flags"}>
                    {dataIntegrityWarning}
                    {renderFlags(text, aPackage)}
                </div>
            </span>
        );
    }

    function renderSize(text: any, record: Package) {
        return <>
            {FormatUtils.formatBytes(record.activeSize)}
            <br/>
            {record.activeFileCount} {intlMessage("package.files", {files: record.activeFileCount})}
        </>
    }

    function packageApproved(aPackage: Package) {
        return !aPackage.approveRequired || (aPackage.approveRequired && aPackage.approved);
    }

    function renderActions(text: any, record: Package) {

        let forwardAction;
        let addRecipientAction;
        let resendNotificationsAction;
        let editAction;
        let deleteAction;
        let removeRecipientAction;
        let approveAction;
        let disapproveAction;
        let setApproversAction;
        let persistentAction;

        // FORWARD
        if (packageApproved(record) &&
            (record.workflowState === PackageWorkflowState.ACTIVE) &&
            (record.checkState === PackageCheckState.CLEAN) &&
            appContext.user?.hasPermission(UserPermission.SEND_PACKAGE) &&
            record.activeFileCount! > 0 &&
            record.limitAccessCount === 0) {

            forwardAction = <Menu.Item>
                <Button
                    onClick={(e: any) => history.push(routesMap.PackageForward.path.replace(/:id.*$/, record.id!))}
                    type="default" className={"ant-btn-icon-only"}>
                    <ForwardFilled/>
                    {intlMessage("common.forward-package")}
                </Button>
            </Menu.Item>
        }

        if (packageApproved(record) &&
            ownPackage(record) &&
            (record.workflowState === PackageWorkflowState.ACTIVE && (record.checkState === PackageCheckState.CLEAN || record.checkState === PackageCheckState.UNKNOWN))) {


            if (appContext.user?.hasPermission(UserPermission.SEND_PACKAGE) &&
                record.accessType !== PackageAccessType.BRIEFCASE) {

                addRecipientAction = <Menu.Item>
                    <Button onClick={(e: any) => onAddRecipient(record, e)} type="default"
                            className={"ant-btn-icon-only"}>
                        <MailFilled/>
                        {intlMessage("common.add-recipients")}
                    </Button>
                </Menu.Item>
            }

            editAction = <Menu.Item>
                <Button onClick={(e: any) => onEdit(record, e)} type="default" className={"ant-btn-icon-only"}>
                    <EditFilled/>
                    {intlMessage("common.edit")}
                </Button>
            </Menu.Item>

        }

        // mazani jen svych/odeslanych zasilek. Prijate zasilky s emazou odstranenim prijemce
        if (packageApproved(record) &&
            ((ownPackage(record)) && (record.workflowState === PackageWorkflowState.ACTIVE && (record.checkState === PackageCheckState.CLEAN || record.checkState === PackageCheckState.UNKNOWN)))
            || record.workflowState === PackageWorkflowState.REQUESTED
            || record.accessType === PackageAccessType.BRIEFCASE) {

            deleteAction = <Menu.Item>
                <OkLeftPopconfirm
                    title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: record.name ? record.name : record.id})}
                    onConfirm={() => onDeleteConfirm(record)}
                    okText={intlMessage("common.yes")}
                    cancelText={intlMessage("common.no")}>

                    <Button danger={true} className={"ant-btn-icon-only"}>
                        <DeleteFilled/>
                        {intlMessage("common.delete")}
                    </Button>
                </OkLeftPopconfirm>
            </Menu.Item>

            if (ownPackage(record) && record.accessType !== PackageAccessType.BRIEFCASE) {
                resendNotificationsAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-resend-notifications", {packageName: record.name ? record.name : record.id})}
                        onConfirm={() => onResendNotificationsConfirm(record)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>

                        <Button danger={true} className={"ant-btn-icon-only"}>
                            <MailFilled/>
                            {intlMessage("packages-inbox-outbox.resend-notifications")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>
            }

        }

        if (record.workflowState === PackageWorkflowState.REQUESTED && props.tableType === "INBOX") {

            resendNotificationsAction = <Menu.Item>
                <OkLeftPopconfirm
                    title={intlMessage("packages-inbox-outbox.confirm-resend-notifications", {packageName: record.name ? record.name : record.id})}
                    onConfirm={() => onResendNotificationsConfirm(record)}
                    okText={intlMessage("common.yes")}
                    cancelText={intlMessage("common.no")}>

                    <Button danger={true} className={"ant-btn-icon-only"}>
                        <MailFilled/>
                        {intlMessage("packages-inbox-outbox.resend-notifications")}
                    </Button>
                </OkLeftPopconfirm>
            </Menu.Item>
        }

        if (packageApproved(record) &&
            (props.tableType === "INBOX" ||  record.contributor) &&
            (record.workflowState === PackageWorkflowState.ACTIVE && record.checkState === PackageCheckState.CLEAN) &&
            record.sender?.email != appContext.user?.email) {

            removeRecipientAction = <Menu.Item>
                <OkLeftPopconfirm
                    title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: record.name ? record.name : record.id})}
                    onConfirm={() => onRemoveRecipient(record)}
                    okText={intlMessage("common.yes")}
                    cancelText={intlMessage("common.no")}>

                    <Button danger={true} className={"ant-btn-icon-only"}>
                        <DeleteFilled/>
                        {intlMessage("common.delete")}
                    </Button>
                </OkLeftPopconfirm>
            </Menu.Item>
        }

        if (props.tableType === "APPROVE_LIST" &&
            appContext.user &&
            appContext.user.hasPermission(UserPermission.APPROVE_SEND_PACKAGE)) {
            if (!record.approved) {
                approveAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-approve", {packageName: record.name ? record.name : record.id})}
                        onConfirm={() => onApprove(record)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>

                        <Button danger={true} className={"ant-btn-icon-only"}>
                            <CheckCircleOutlined/>
                            {intlMessage("common.approve")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>
            }

            if (!record.disapproved) {
                disapproveAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-disapprove", {packageName: record.name ? record.name : record.id})}
                        onConfirm={() => onDisapprove(record)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>

                        <Button danger={true} className={"ant-btn-icon-only"}>
                            <CloseCircleOutlined/>
                            {intlMessage("common.disapprove")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>
            }
            // akce zatim v seznamu zasilek neukazeme, ale protoze jsem to uz udelal jen je skryju a po nasazeni v pente uvidime co s tim provedeme
            approveAction = undefined;
            disapproveAction = undefined;
        }

        if (record.canSetApprover) {
            setApproversAction = <Menu.Item>
                <Button onClick={() => onSetApprovers(record)}
                        type="default"
                        className={"ant-btn-icon-only"}>
                    <MailFilled/>
                    {intlMessage("package-modal.set-approver.title")}
                </Button>
            </Menu.Item>;
        }

        if (ownPackage(record) && record.workflowState === PackageWorkflowState.ACTIVE && record.checkState === PackageCheckState.CLEAN &&
            appContext.user?.hasPermission(UserPermission.SET_PACKAGE_PERSISTENT)) {
            if (record.persistent) {
                persistentAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-list.confirm-set-temporary", {packageName: record.name ? record.name : record.id})}
                        onConfirm={() => onUnsetPersistentPackageConfirm(record)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <PushpinFilled/>
                            {intlMessage("package.set-temporary")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>

            } else {
                persistentAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-list.confirm-set-persistent", {packageName: record.name ? record.name : record.id})}
                        onConfirm={() => onSetPersistentPackageConfirm(record)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <PushpinOutlined/>
                            {intlMessage("package.set-persistent")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>

            }
        }

        const menu = <Menu className={"more-actions"}>
            {forwardAction}
            {addRecipientAction}
            {resendNotificationsAction}
            {editAction}
            {approveAction}
            {disapproveAction}
            {setApproversAction}
            {persistentAction}
            {removeRecipientAction}
            {deleteAction}
        </Menu>;

        const anyAction = forwardAction || addRecipientAction || editAction || deleteAction || removeRecipientAction || approveAction || disapproveAction ||
            resendNotificationsAction || setApproversAction || persistentAction;

        if (anyAction) {
            return (
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link ant-btn ant-btn-icon-only ant-btn-default" href="#" title={intlMessage("common.actions")}>
                        <BarsOutlined/>
                    </a>
                </Dropdown>
            );
        }
        return "";

    }

    function onSetPersistentPackageConfirm(record: Package) {
        packageService.setPersistentPackage(record)
            .then(() => {
                message.success(intlMessage("package.marked-as-persistent", {packageName: record.name ? record.name : record.id}));

                props.reload();
            });
    }

    function onUnsetPersistentPackageConfirm(record: Package) {
        packageService.unsetPersistentPackage(record)
            .then(() => {
                message.success(intlMessage("package.marked-as-temporary", {packageName: record.name ? record.name : record.id}));

                props.reload();
            });
    }


    function onDeleteConfirm(record: Package) {
        packageService.delete(record)
            .then(() => {
                message.success(intlMessage('package.deleted', {packageName: record.name ? record.name : record.id}));
                props.reload();
            });
    }

    function onResendNotificationsConfirm(record: Package) {
        packageService.resendNotifications(record)
            .then(() => {
                message.success(intlMessage('package.notifications-resent', {packageName: record.name ? record.name : record.id}));
                props.reload();
            });
    }

    function renderUserFlags(pkg: Package) {
        return (<>{pkg.userFlags?.flagged ?
            <a className={"button"} title={intlMessage("packages.unset-flag")} onClick={() => setFlagged(pkg, false)}><StarFilled className={"filled"}/></a> :
            <a className={"button"} title={intlMessage("packages.set-flag")} onClick={() => setFlagged(pkg, true)}><StarOutlined className={"outlined"} style={{opacity: 0.2}}/></a>
        }
        </>);
    }

    function setFlagged(pkg: Package, value: boolean) {
        if (!pkg.userFlags) {
            pkg.userFlags = {flagged: false, viewed: false}
        }

        pkg.userFlags!.flagged = value;

        setTimeout(forceUpdate, 10)

        if (value) {
            packageService.setFlagged(pkg);
        } else {
            packageService.unsetFlagged(pkg);
        }
    }

    function renderFlags(text: any, aPackage: Package) {
        let cooperativeFlag = false;

        return (
            <>
                {aPackage.approveRequired ? <AuditOutlined className={"info"} title={intlMessage("packages-list.approve-required")}/> : ""}

                {aPackage.persistent && <PushpinFilled
                    className={"info"}
                    title={intlMessage('packages-inbox-outbox.persistent-package')}/>}
                {aPackage.passwordProtected ? <LockFilled
                    className={"info"}
                    title={intlMessage('packages-inbox-outbox.protected-download-title')}/> : ""}
                {aPackage.accessType === PackageAccessType.PUBLIC && aPackage.workflowState != PackageWorkflowState.REQUESTED ? <GlobalOutlined
                    className={"info"}
                    title={intlMessage('packages-list.public-access-title')}/> : ""}


                {aPackage.flags!.map((flag) => {
                    switch (flag) {
                        case PackageFlag.ARCHIVED:
                            return <FolderAddFilled key={"" + aPackage.id + flag.toString()} className={"info"} title={intlMessage("packages-inbox-outbox.flags.ARCHIVED")}/>
                        case PackageFlag.OVERSIZE:
                            return <ColumnHeightOutlined key={"" + aPackage.id + flag.toString()} className={"danger"} title={intlMessage("packages-inbox-outbox.flags.OVERSIZE")}/>
                        case PackageFlag.ENCRYPTED_CONTENT:
                            return <FileUnknownFilled key={"" + aPackage.id + flag.toString()} className={"danger"} title={intlMessage("packages-inbox-outbox.flags.ENCRYPTED_CONTENT")}/>
                        case PackageFlag.FORWARDED:
                            return <ForwardOutlined key={"" + aPackage.id + flag.toString()} className={"info"} title={intlMessage("packages-inbox-outbox.flags.FORWARDED")}/>
                        case PackageFlag.COOPERATIVE:
                            cooperativeFlag = ownPackage(aPackage);
                            return ownPackage(aPackage) && <ShareAltOutlined key={"" + aPackage.id + flag.toString()} className={"info"} title={intlMessage("packages-inbox-outbox.flags.COOPERATIVE")}/>
                        case PackageFlag.DLP_MATCHED:
                        default:
                            return "";
                    }
                })}

                {props.tableType === "INBOX" && !cooperativeFlag && aPackage.contributor &&
                    <ShareAltOutlined className={"info"} title={intlMessage("packages-inbox-outbox.flags.COOPERATIVE")}/>
                }

                {aPackage.limitAccessCount! > 0 && <ImSortNumbericDesc className={aPackage.limitAccessCount! <= aPackage.accessCount! ? "color-red-100" : ""}
                                                                       title={intlMessage("package-detail.package-with-limited-access-count") + " (" + intlMessage("package-detail.access-limit-count-usage", {usedCount: aPackage.accessCount, limitCount: aPackage.limitAccessCount}) + ")"}/>}
            </>
        );
    }

    function renderRecipients(text: any, aPackage: Package) {
        if (!aPackage.internalRecipients && !aPackage.externalRecipients) {
            return intlMessage('packages-inbox-outbox.no-recipients');
        }

        const recipients = [];

        if (aPackage.internalRecipients) {
            recipients.push(...aPackage.internalRecipients.map(recipient => recipient.email));
        }

        if (aPackage.externalRecipients) {
            recipients.push(...aPackage.externalRecipients.map(recipient => recipient.email));
        }

        return (
            recipients.join(', ')
        );
    }

    function renderState(text: any, aPackage: Package) {
        text = `PackageCheckState.${text}`;

        if (aPackage.workflowState === PackageWorkflowState.REQUESTED) {
            text = `PackageWorkflowState.${PackageWorkflowState.REQUESTED}`;
        }

        let approveState = ""
        if (aPackage.approveRequired) {
            approveState = intlMessage("package-detail.waiting-for-approve");
            if (aPackage.approved) {
                approveState = intlMessage("package-detail.approved");
            }
            if (aPackage.disapproved) {
                approveState = intlMessage("package-detail.disapproved");
            }
        }

        return (
            <span>{intlMessage(text)}{aPackage.approveRequired && <><br/><span style={{textTransform: "lowercase"}}>{approveState}</span></>}</span>
        );
    }

    function onEdit(record: Package, e?: React.MouseEvent) {
        e!.preventDefault();

        setModal((prevState: any) => {
                return (
                    {
                        ...prevState.modal,
                        visible: true,
                        title: intlMessage("package-edit.title", {name: record.name ? record.name : record.id}),
                        operation: ModalOperation.Update,
                        model: record
                    }
                )
            }
        );
    }

    function onAddRecipient(record: Package, e?: React.MouseEvent) {
        e!.preventDefault();

        setModal((prevState: any) => {
                return (
                    {
                        ...prevState.modal,
                        visible: true,
                        title: intlMessage("package-add-recipient.title", {name: record.name ? record.name : record.id}),
                        operation: ModalOperation.Add,
                        model: record
                    }
                )
            }
        );
    }

    // odstrani prihlaseneho uzivatele z prijemcu
    function onRemoveRecipient(record: Package) {
        packageService.removeRecipient(record)
            .then(() => {
                message.success(intlMessage('package.deleted', {packageName: record.name ? record.name : record.id}));

                props.reload();
            });
    }

    function onApprove(record: Package) {
        packageService.approve(record)
            .then(() => {
                message.success(intlMessage('packages-inbox-outbox.approved', {packageName: record.name ? record.name : record.id}));

                props.reload();
            });
    }

    function onDisapprove(record: Package) {
        packageService.disapprove(record)
            .then(() => {
                message.success(intlMessage('packages-inbox-outbox.disapproved', {packageName: record.name ? record.name : record.id}));

                props.reload();
            });
    }

    function onSetApprovers(record: Package) {
        setSetApproverModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-modal.set-approver.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Add,
                model: record
            });
        });
    }

}

export default PackageList;
