import Icon from "@ant-design/icons";
import {Alert, Button, Col, Collapse, Form, Input, Modal, Row, Select} from 'antd';
import {useForm} from "antd/lib/form/Form";
import Cookies from "js-cookie";
import {ReactNode, useContext, useEffect, useState} from "react";
import {AdalConfig, AuthenticationContext} from "react-adal";
import {useHistory, useLocation} from "react-router";
import {AppContextContext, AuthServiceContext, LocalStorageServiceContext, MultiFactorKeyServiceContext} from "../Contexts";
import {MultiFactorAuthentication} from "../domain/MultiFactorAuthentication";
import {MultiFactorKeyType} from "../domain/MultiFactorKey";
import {User} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import {anonymousRoutesMap} from "./AnonymousRoutes";
import {HomePageContent, OidcSettings, OidcVendor} from "./common/ApplicationConfig";
import {AppleIcon, GoogleIcon, IconLock, MicrosoftIcon, OpenIdIcon} from "./common/CustomIcons";
import {DocumentTitle} from "./DocumentTitle";
import ResetPassword from "./ResetPassword";
import {routesMap} from "./Routes";

enum PageModes {
    DEFAULT,
    LOST_PASSWORD
}

interface MultiFactor {
    required: boolean;
    availableTypes: MultiFactorKeyType[];
    selected?: MultiFactorKeyType,
}

const {Panel} = Collapse;

function LoginPage() {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig!;
    const authService = useContext(AuthServiceContext);
    const multiFactorKeyService = useContext(MultiFactorKeyServiceContext);
    const localStorageService = useContext(LocalStorageServiceContext);
    const intlMessage = useIntlMessage("login-page");
    const history = useHistory();
    const [loginForm] = useForm();
    const location = useLocation();
    const [notice, setNotice] = useState("");
    const [pageMode, setPageMode] = useState<PageModes>();
    const [multiFactor, setMultiFactor] = useState<MultiFactor>({
        required: false,
        availableTypes: []
    });

    const protocol = process.env.NODE_ENV === 'development' ? "http:" : "https:";

    const adalConfig: AdalConfig | undefined = applicationConfig.adfsConfig && applicationConfig.adfsConfig[0] ? {
        tenant: applicationConfig.adfsConfig[0].tenant,
        clientId: applicationConfig.adfsConfig[0].clientId,
        redirectUri: protocol + "//" + window.location.host + applicationConfig.adfsConfig[0].redirectUri,
        instance: applicationConfig.adfsConfig[0].instance,
        cacheLocation: 'localStorage',
        extraQueryParameter: 'scope=openid&response_mode=form_post',
    } : undefined;

    /*
    // d54842fc-fefc-4fb0-bf8f-654db6305f02/v2.0/
    // CXg8Q~0l1iDY6K2VM2USlIIRgggioQwC7nSpjbsh
    const adalConfig: AdalConfig | undefined = applicationConfig.adfsConfig && applicationConfig.adfsConfig[0] ? {
        tenant: 'd54842fc-fefc-4fb0-bf8f-654db6305f02',
        clientId: "ad4ba033-52d4-4e5f-bf94-0d70e3fe9271",
        redirectUri: protocol + "//" + window.location.host + applicationConfig.adfsConfig[0].redirectUri,
        instance: "https://login.microsoftonline.com/",
        cacheLocation: 'localStorage',
        extraQueryParameter: 'scope=openid&response_mode=form_post',
    } : undefined;
*/
    const loginPageOnHomepage: boolean = (applicationConfig.homePageContent === HomePageContent.LOGIN);
    const allowAnonymousUpload: boolean = applicationConfig.allowAnonymousUpload === true;

    const [termsAndDisclaimerHead, setTermsAndDisclaimerHead] = useState(applicationConfig.termsAndDisclaimerHead__EN);
    const [termsAndDisclaimerBody, setTermsAndDisclaimerBody] = useState(applicationConfig.termsAndDisclaimerBody__EN);

    useEffect(() => {
        if (location.pathname === "/reset-password-token") {
            setPageMode(PageModes.LOST_PASSWORD);
        } else {
            setPageMode(PageModes.DEFAULT);
        }

        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has('mfa')) {
            queryParams.delete('mfa');

            history.replace({
                search: queryParams.toString()
            })

            onMultiFactorAuthenticationRequired();
        }

        if (applicationConfig.termsAndDisclaimer === "ENFORCE") {

            const termsAndDisclaimerSeen = localStorageService.getIndexedItem(LocalStorageNamespace.TermsAndDisclaimer, "termsAndDisclaimerSeen");

            let showTerms = false;
            if (!termsAndDisclaimerSeen) {
                showTerms = true;
            } else {
                const item = JSON.parse(termsAndDisclaimerSeen)
                const now = new Date();

                if (now.getTime() > item.expiry || applicationConfig.rememberTermsAndDisclaimerFor! < item.ttl!) {
                    showTerms = true;
                }
            }

            if (showTerms) {
                showTermsAndDisclaimer();
            }
        }

    }, [location]);

    useEffect(() => {

        if (Cookies.get('User-Auth-Failed')) {
            setNotice(intlMessage("login.error." + Cookies.get('User-Auth-Failed')));

            Cookies.remove("User-Auth-Failed", {domain: window.location.hostname, path: "/"});
        }

    }, [])

    useEffect(() => {

        if (applicationConfig.termsAndDisclaimer !== "NO") {
            switch (appContext.language) {
                case "en":
                    setTermsAndDisclaimerHead(applicationConfig.termsAndDisclaimerHead__EN);
                    setTermsAndDisclaimerBody(applicationConfig.termsAndDisclaimerBody__EN);
                    break;
                case "cs":
                    setTermsAndDisclaimerHead(applicationConfig.termsAndDisclaimerHead__CS);
                    setTermsAndDisclaimerBody(applicationConfig.termsAndDisclaimerBody__CS);
                    break;
                case "hu":
                    setTermsAndDisclaimerHead(applicationConfig.termsAndDisclaimerHead__HU);
                    setTermsAndDisclaimerBody(applicationConfig.termsAndDisclaimerBody__HU);
                    break;
                default:
                    setTermsAndDisclaimerHead(applicationConfig.termsAndDisclaimerHead__EN);
                    setTermsAndDisclaimerBody(applicationConfig.termsAndDisclaimerBody__EN);
            }
        }

    }, [appContext.language]);

    return (
        <DocumentTitle title={`${applicationConfig.title}: ${intlMessage('login.title')}`}>
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                {
                    pageMode === PageModes.LOST_PASSWORD &&

                    <Col><ResetPassword switchMode={() => setPageMode(PageModes.DEFAULT)}/></Col>
                }

                {
                    pageMode === PageModes.DEFAULT &&

                    <Col id={"login-form"}>
                        <h1>{intlMessage('login.title')}</h1>

                        {renderLoginForm()}
                    </Col>
                }
            </Row>
        </DocumentTitle>
    )

    function adfs() {
        if (adalConfig) {
            const authContext = new AuthenticationContext(adalConfig);
            authContext.login();
        }
    }

    function onCancelLogin() {
        setNotice("");
        setMultiFactor({
            required: false,
            availableTypes: []
        });
    }

    function renderLoginForm() {

        return (
            <div>
                {notice &&
                    <Alert style={{marginBottom: 24}} message={notice} type="error"
                           showIcon={false}/>
                }

                {!multiFactor.required && (adalConfig || applicationConfig.oidcConfig) &&
                    <>
                        {applicationConfig.adfsConfig && applicationConfig.adfsConfig.length > 0 &&
                            <div className={"remote-login"}>
                                <a onClick={adfs}
                                   className={"ant-btn ant-btn-lg login-btns remote-login-btn"}>{intlMessage("login.login_as_internal")}</a>
                            </div>
                        }

                        {applicationConfig.oidcConfig && applicationConfig.oidcConfig.length > 0 &&
                            applicationConfig.oidcConfig.map(renderOidcProvider)
                        }
                        <div className={"login-or"}>{intlMessage('common.or')}</div>
                    </>}

                {!multiFactor.required && loginPageOnHomepage && allowAnonymousUpload &&
                    <>
                        <Row>
                            <Col span={24} className={"upload-package-without-login"}>
                                <a onClick={() => history.push(anonymousRoutesMap.UploadPackage.path)}
                                   className={"ant-btn ant-btn-lg login-btns upload-package-without-login-btn"}>
                                    {intlMessage("login.upload-without-login")}
                                    <br/>
                                    <span>{intlMessage("login.upload-without-login_extra")}</span>
                                </a>
                            </Col>
                        </Row>
                        <div className={"login-or"}>{intlMessage('common.or')}</div>
                    </>}

                <Form form={loginForm} layout={"vertical"} className="login-form" onFinish={handleSubmit}>

                    {!multiFactor.required &&
                        <>
                            <Form.Item label={intlMessage("common.username")} name={"username"}
                                       rules={[
                                           {required: true, message: intlMessage("required.username")}
                                       ]}>
                                <Input type="text" name="username" autoFocus={true} maxLength={50}/>
                            </Form.Item>

                            <Form.Item label={intlMessage("common.password")} name={"password"}
                                       rules={[
                                           {required: true, message: intlMessage("required.password")}
                                       ]}>
                                <Input type="password" name="password" maxLength={30}/>
                            </Form.Item>

                            <div className={"lostPasswordLink"}>
                                <a onClick={() => setPageMode(PageModes.LOST_PASSWORD)}>{intlMessage('login.lost-password')}</a>
                            </div>
                        </>
                    }

                    {
                        multiFactor.required &&

                        <div>
                            <p>{intlMessage('login.multi-factor.prompt')}</p>

                            {
                                multiFactor.availableTypes.length > 1 &&

                                <Form.Item label={intlMessage("login.multi-factor.type")} name={"type"} rules={[{required: true}]}>
                                    <Select style={{width: 120}}
                                            onChange={(value) => {
                                                setMultiFactor(prevState => {
                                                    return {
                                                        ...prevState,
                                                        selected: value as MultiFactorKeyType

                                                    }
                                                })
                                            }}>
                                        {multiFactor.availableTypes.map(value =>
                                            <Select.Option key={value} value={value}>{value}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            }

                            {renderTypeSpecificElements()}
                        </div>
                    }

                    <Button type="primary" htmlType="submit" className={"login-btns"} size="large" disabled={isFido2MfaRequiredButNotSupported()}>{intlMessage("login.submit")}</Button>

                    {
                        multiFactor.required &&

                        <Button type="ghost" style={{width: "100%", marginTop: '1em'}} size="middle" onClick={onCancelLogin}>{intlMessage("login.cancel")}</Button>
                    }

                    {applicationConfig.termsAndDisclaimer !== "NO" &&
                        <div className={"terms-and-disclaimer"} onClick={showTermsAndDisclaimer}>{termsAndDisclaimerHead}</div>
                    }

                </Form>

                {
                    !multiFactor.required && !loginPageOnHomepage && applicationConfig!.allowUserUpload &&

                    <div className={"login-back"}><a
                        onClick={() => history.push(anonymousRoutesMap.Welcome.path)}>{intlMessage("common.back")}</a>
                    </div>
                }
            </div>
        )
    }

    function renderOidcProvider(oidcProvider: OidcSettings) {

        let icon;
        let providerName;

        switch (oidcProvider.type) {
            case OidcVendor.GOOGLE:
                icon = <Icon component={GoogleIcon}/>;
                providerName = "GOOGLE";
                break;
            case OidcVendor.MS:
                icon = <Icon component={MicrosoftIcon}/>;
                providerName = "MICROSOFT";
                break;
            case OidcVendor.APPLE:
                icon = <Icon component={AppleIcon}/>;
                providerName = "APPLE";
                break;
            case OidcVendor.GENERIC:
            default:
                icon = <Icon component={OpenIdIcon}/>;
                providerName = oidcProvider.name;
                break;
        }

        return (<div className={"remote-login"} key={oidcProvider.id}>
            <a href={oidcProvider.authUri}
               className={"ant-btn ant-btn-lg login-btns remote-login-btn"}>{icon} {oidcProvider.name}</a>
        </div>);
    }

    function showTermsAndDisclaimer() {

        Modal.info({
            title: <p dangerouslySetInnerHTML={{__html: termsAndDisclaimerHead!}}/>,
            content: (
                <div dangerouslySetInnerHTML={{__html: termsAndDisclaimerBody!}}/>
            ),
            onOk() {
                const item = {
                    value: "true",
                    expiry: new Date().getTime() + applicationConfig.rememberTermsAndDisclaimerFor! * 60 * 1000,
                    ttl: applicationConfig.rememberTermsAndDisclaimerFor!,
                }
                localStorageService.setIndexedItem(LocalStorageNamespace.TermsAndDisclaimer, "termsAndDisclaimerSeen", JSON.stringify(item));
            },
            width: 800
        });
    }

    function renderTypeSpecificElements() {

        switch (multiFactor.selected) {
            case 'FIDO2':
                return (
                    <>
                        {
                            !navigator.credentials &&
                            <p>
                                {intlMessage('multi-factor-key.not-supported.fido2')}
                            </p>
                        }
                        {
                            navigator.credentials &&
                            <Form.Item>
                                <span className="ant-form-text">{intlMessage('login.multi-factor.prompt.fido2')}</span>
                            </Form.Item>
                        }
                    </>
                );
            case 'TOTP':
                return (
                    <Form.Item label={intlMessage("login.multi-factor.totp.code")} name={"totpCode"}
                               rules={[
                                   {required: true, message: intlMessage("login.multi-factor.totp.required")}
                               ]}>
                        <Input type="text" name="totpCode" autoFocus={true} maxLength={10}/>
                    </Form.Item>
                );
            default:
                return "";
        }
    }

    function isFido2MfaRequiredButNotSupported() {
        return multiFactor.required && multiFactor.selected === MultiFactorKeyType.FIDO2 && !navigator.credentials;
    }

    function isFido2Supported() {
        return navigator.credentials !== undefined;
    }

    function startFidoMfaAuthentication(onOk: any, onError: any) {
        multiFactorKeyService.startFidoAuthentication()
            .then(requestOptions => {
                navigator.credentials
                    .get({publicKey: requestOptions})
                    .then(result => {
                        const fidoMfa = MultiFactorAuthentication.newFromFidoAssertionResponse(result as PublicKeyCredential);

                        authService.mfaAuthenticate(fidoMfa).then(onOk, onError);
                    })
                    .catch(() => setNotice(intlMessage('login.multi-factor.error')))
            })
    }

    function onSubmitOk(user: User) {
        setNotice("");

        appContext.user = user;

        history.replace(routesMap.PackagesInbox.path);
    }

    function onSubmitError(error: any) {
        const reason = error.response.headers['x-sofie-response-reason'];

        if (error.response && error.response.status === 401 && reason === 'Multi-factor authentication required') {
            onMultiFactorAuthenticationRequired();
        } else if (error.response && error.response.status === 403 && reason === 'login.failed') {
            setNotice(intlMessage('login.failed'));
        } else if (error.response && error.response.status === 401 && reason === 'USER_ALREADY_EXISTS') {
            setNotice(intlMessage('login.error.USER_ALREADY_EXISTS'));
        } else if (error.response && error.response.status === 429) {
            setNotice(error.response.data);
        } else {
            setNotice(error.response.data);
        }
    }

    function onMultiFactorAuthenticationRequired() {
        authService.getAvailableMfaTypes().then(availableTypes => {
            const selectedMultiFactor = (availableTypes.length > 0) ? availableTypes[0] : undefined;

            setNotice("");

            setMultiFactor({
                required: true,
                availableTypes,
                selected: selectedMultiFactor
            });

            if (selectedMultiFactor === 'FIDO2' && isFido2Supported()) {
                startFidoMfaAuthentication(onSubmitOk, onSubmitError);
            }

            loginForm.setFieldsValue({type: selectedMultiFactor});
        });
    }

    function handleSubmit(values: any) {
        // multi-factor authentication is not required

        if (!multiFactor.required) {
            authService.authenticate(values.username, values.password).then(onSubmitOk, onSubmitError);

            return;
        }

        // multi-factor authentication is required

        switch (multiFactor.selected) {
            case MultiFactorKeyType.FIDO2:
                startFidoMfaAuthentication(onSubmitOk, onSubmitError);

                break;
            case MultiFactorKeyType.TOTP:
                const otpMfa = MultiFactorAuthentication.newFromTotpCode(values.totpCode);

                authService.mfaAuthenticate(otpMfa).then(onSubmitOk, onSubmitError);

                break;
            default:
                throw new Error('Unsupported multi-factor authentication type');
        }

    }

}

export default LoginPage;