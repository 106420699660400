import {LoadingOutlined} from '@ant-design/icons';
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-numberformat/locale-data/cs';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-pluralrules/locale-data/cs';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/cs';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import Axios from "axios";
import Cookies from "js-cookie";
import {configure} from "mobx"
import {Provider} from "mobx-react";
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import App from "./components/App";
import {DocumentTitle} from "./components/DocumentTitle";
import {appContext, authService, contextServices} from "./Contexts";
import {User} from "./domain/User";
import './index.css';
import {loadMessages, resolveAvailableLocale, updateLocaleResources} from "./sal-ui/Intl";
import AxiosService from "./utils/AxiosService";
import {ConfigProvider} from "antd";

// load CSP nonce from cookie

const cspNonce = Cookies.get("CSP-Nonce");

if (cspNonce !== undefined) {
    appContext.cspConfig = {
        nonce: cspNonce
    }

    Cookies.remove("CSP-Nonce", {domain: window.location.host, path: '/'});
}

// configure application services

configure({useProxies: "ifavailable"});

AxiosService.setUpInterceptors(appContext, authService);

authService.loadSavedInformationFromLocalStorage();

// po prihlaseni z ADFS potrebujeme znovu nacist prihlaseneho uzivatele
// to same pri reloadu stranky ... kdy je user prihlasen obnovime si info z backendu

const reloadUser = Cookies.get('User-Load-Logged-User');

if (appContext.user || reloadUser) {
    authService.loadLoggedInUser()
        .then(user => appContext.user = User.fromPOJO(user));
}

const RELOAD_INTERVAL_IF_SERVER_UNAVAILABLE_MS = 5000;

let appWasUnavailable = false;

const loadConfig = () => {

    Axios.get("/api/public/static-resource/config", {baseURL: ""}).then(value => {

        if (appWasUnavailable) {
            window.location.reload();
        }

        contextServices.applicationConfig = value.data;

        appContext.applicationConfig = value.data;

        if (!appContext.language || appContext.language === "") {
            appContext.language = resolveAvailableLocale(contextServices.applicationConfig.defaultLanguage);
        }

        updateLocaleResources(appContext.language);

        document.documentElement.lang = appContext.language;

        loadMessages().then(() => {
            const container = document.getElementById('root');

            const root = createRoot(container!); // createRoot(container!) if you use TypeScript
            root.render(
                <Provider {...contextServices}>
                    <HelmetProvider >
                        <DocumentTitle title={contextServices.applicationConfig.title} >
                            <BrowserRouter>
                                <App/>
                            </BrowserRouter>
                        </DocumentTitle>
                    </HelmetProvider>
                </Provider>
            );
        });
    }, (error: any) => {

        let loadingInfo = "Hold on, the app will be available any moment";

        if (appContext.language === "cs") {
            loadingInfo = "Vydržte, aplikace bude dostupná každou chvílí";
        }

        if (error.response.status === 403) {
            loadingInfo = "Sorry, the app is unavailable.";

            if (appContext.language === "cs") {
                loadingInfo = "Omlouváme se, aplikace je nedostupná";
            }
        }

        const container = document.getElementById('root');
        const root = createRoot(container!); // createRoot(container!) if you use TypeScript

        root.render(
            <ConfigProvider csp={appContext.cspConfig} iconPrefixCls={"anticon"}>
                <div style={{textAlign: "center", position: "fixed", width: "100%", top: "40%"}}>
                    <div style={{fontSize: "30px", paddingBottom: "32px"}}>{loadingInfo}</div>
                    <div><LoadingOutlined style={{fontSize: "100px"}}/></div>
                </div>
            </ConfigProvider>
        );

        appWasUnavailable = true;

        setTimeout(loadConfig, RELOAD_INTERVAL_IF_SERVER_UNAVAILABLE_MS);
    });
}

loadConfig();
