import {ConfigProvider} from "antd";
import cs_CZ from "antd/lib/locale-provider/cs_CZ";
import en_US from "antd/lib/locale-provider/en_US";
import hu_HU from "antd/lib/locale-provider/hu_HU";
import {observer} from "mobx-react"
import "moment/locale/cs";
import "moment/locale/hu";
import {useContext} from 'react';
import {RawIntlProvider} from "react-intl";
import {AppContextContext} from "../Contexts";
import {intl} from "../sal-ui/Intl";
import AnonymousHomePage from "./AnonymousHomePage";
import HomePage from "./HomePage";

function App() {
    const appContext = useContext(AppContextContext);

    return (
        <RawIntlProvider value={intl} key={appContext.language}>
            <ConfigProvider locale={getEffectiveLocale()} csp={appContext.cspConfig} iconPrefixCls={"anticon"}>
                {appContext.user ? <HomePage/> : <AnonymousHomePage/>}
            </ConfigProvider>
        </RawIntlProvider>
    )

    function getEffectiveLocale() {
        if (appContext.language === 'cs') {
            return cs_CZ;
        } else {
            return (appContext.language === 'hu')
                ? hu_HU
                : en_US;
        }
    }
}

export default observer(App);
